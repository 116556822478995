export const PURPOSES = [
  {
    name: '恋活パーティー',
    slug: 'lover',
    spName: '恋人を見つけたい',
    spDescription: 'カジュアルな出会いを探している方へ',
    spSecondaryDescription: '恋人を見つけたい',
    spImage: 'lover.png'
  },
  {
    name: '婚活パーティー/お見合いパーティー',
    slug: 'marriage_partner',
    spName: '結婚相手を見つけたい',
    spDescription: '結婚相手を探している方へ',
    spSecondaryDescription: '結婚相手を見つけたい',
    spImage: 'married.png'
  },
  {
    name: '趣味コン/体験',
    slug: 'same_hobbies',
    spName: '趣味でつながりたい',
    spDescription: '共通の話題をもとに交流したい方へ',
    spSecondaryDescription: '趣味でつながりたい',
    spImage: 'hobbies.png',
    subCategories: [
      {
        name: '体験(趣味＋)',
        slug: 'syumiplus',
        description: '何かを本格的に体験しながら交流したい方へ',
        subList: [
          { name: '利き酒', slug: 'syumiplus_alcohol' },
          { name: '料理', slug: 'syumiplus_cooking' },
          { name: 'ボルダリング', slug: 'syumiplus_bouldering' },
          { name: 'ランニング', slug: 'syumiplus_run' },
          { name: 'ヨガ', slug: 'syumiplus_yoga' },
          { name: 'ボードゲーム', slug: 'syumiplus_boardgame' },
          { name: '英会話', slug: 'syumiplus_english' },
          { name: '書道', slug: 'syumiplus_shodou' },
          { name: 'カメラ', slug: 'syumiplus_camera' },
          { name: 'ものづくり', slug: 'syumiplus_craft' }
        ]
      },
      {
        name: '趣味コン',
        slug: 'interest',
        description: '共通の話題をもとに交流したい方へ',
        haveSubItemList: true,
        subList: [
          {
            name: 'ゲーム/アニメ',
            slug: 'game',
            subItems: [
              { name: 'サバゲー', slug: 'survivalgame' },
              { name: '謎解き', slug: 'nazotoki' },
              { name: 'アニメ', slug: 'anime' },
              { name: '人狼', slug: 'jinro' },
              { name: 'その他のゲーム', slug: 'game-other' }
            ]
          },
          {
            name: 'スポーツ/フィットネス',
            slug: 'sports',
            subItems: [
              { name: 'ウォーキング', slug: 'walking' },
              { name: 'フットサル', slug: 'futsal' },
              { name: 'テニス', slug: 'tennis' },
              { name: 'ボルダリング', slug: 'bouldering' },
              { name: 'サッカー', slug: 'soccer' },
              { name: '野球', slug: 'baseball' },
              { name: 'その他のスポーツ', slug: 'sports-other' }
            ]
          },
          {
            name: 'アウトドア',
            slug: 'outdoor',
            subItems: [
              { name: '釣り', slug: 'fishing' },
              { name: '旅', slug: 'travel' },
              { name: '婚活バスツアー', slug: 'bus' },
              { name: '観光ツアー', slug: 'tour' },
              { name: '海の体験', slug: 'sea' },
              { name: '川の体験', slug: 'river' },
              { name: '山の体験', slug: 'mount' },
              { name: '空の体験', slug: 'sky' },
              { name: '雪の体験', slug: 'snow' },
              { name: '野菜・果物狩り', slug: 'vegetables' },
              { name: 'その他のアウトドア', slug: 'outdoor-other' }
            ]
          },
          {
            name: 'アート/アミューズメント',
            slug: 'artamuse',
            subItems: [
              { name: '音楽', slug: 'music' },
              { name: 'カラオケ', slug: 'karaoke' },
              { name: '美術館', slug: 'museum' },
              { name: 'お笑い', slug: 'comedy' },
              { name: '日本文化', slug: 'culture' },
              { name: '乗り物', slug: 'vehicle' },
              { name: '映画', slug: 'movie' },
              { name: '鉄道', slug: 'train' },
              { name: 'その他のアミューズメント', slug: 'artamuse-other' }
            ]
          },
          {
            name: '学び',
            slug: 'learn',
            subItems: [
              { name: '料理', slug: 'cooking' },
              { name: '読書', slug: 'books' },
              { name: 'カメラ', slug: 'camera' },
              { name: 'ものづくり', slug: 'craft' },
              { name: '職業体験', slug: 'job' },
              { name: '歴史', slug: 'history' },
              { name: 'その他の学び', slug: 'learn-other' }
            ]
          },
          {
            name: 'ペット/動物',
            slug: 'animal',
            subItems: [
              { name: '猫', slug: 'cat' },
              { name: '犬', slug: 'dog' },
              { name: 'ふくろう', slug: 'owl' },
              { name: 'うさぎ', slug: 'rabbit' },
              { name: 'はりねずみ', slug: 'hedgehog' },
              { name: '爬虫類', slug: 'reptiles' },
              { name: '動物園', slug: 'zoo' },
              { name: 'その他の動物', slug: 'animal-other' }
            ]
          },
          {
            name: 'その他',
            slug: 'interest-other',
            subItems: [
              { name: 'お酒', slug: 'drink' },
              { name: 'その他', slug: 'interest-other' }
            ]
          }
        ]
      }
    ]
  },
  {
    name: '友達作り/オフ会/社会人サークル',
    slug: 'tomokatsu',
    spName: '友達が欲しい',
    spDescription: '趣味サークルや同年代の友達作りをしたい方へ',
    spSecondaryDescription: '友達が欲しい',
    spImage: 'search-friends.png'
  },
  {
    name: '自分磨きセミナー',
    slug: 'jibunmigaki',
    spName: '自分を磨きたい',
    spDescription: '趣味や教養など楽しく自分磨きをしたい方へ',
    spSecondaryDescription: '自分を磨きたい',
    spImage: 'polish-myself.png'
  },
  {
    name: '婚活相談をしたい',
    slug: 'counseling',
    spName: '結婚相談をしたい',
    spDescription: '婚活のプロに1対1で相談したい方へ',
    spSecondaryDescription: '結婚相談をしたい',
    spImage: 'counseling-image.jpeg',
    feature: true
  }
]

export const INTEREST_SLUG = 'interest'
export const SYUMIPLUS_SLUG = 'syumiplus'
