<template>
  <div>
    <div :class="{ nomalMode: modeNormal, headerMode: modeHeader, active: canShowHeader }" class="searchConditions">
      <div v-if="pageDetector.isSimilarEventsPage" class="searchConditions-content listConditions selectForm">
        <transition name="headerSlideDown">
          <div class="optionItemGroup marginB3">
            <div class="optionItem condititon btn-select-tag">
              <custom-nuxt-link :to="searchDetailsPagePath" class="optionItem-value filter">その他のイベント検索</custom-nuxt-link>
            </div>
            <div :class="dateClasses" class="optionItem condititon btn-select-tag" @click="modal = 'calendar'">
              <span class="optionItem-value">
                {{ selectedDateFormatHoliday || '開催日を選ぶ' }}
              </span>
            </div>
          </div>
        </transition>
      </div>
      <template v-else>
        <transition name="headerSlideDown">
          <div v-show="side === 'show' && modal !== 'purpose'" class="searchConditions-content listConditions selectForm">
            <div class="optionItemGroup">
              <div class="optionItem inputSearch" @click="openPurposeModal">
                <span :class="{ active: isSelectedPurpose }" class="icon"><icon fas="fa-search" /></span>
                <span :class="{ activePurpose: isSelectedPurpose }" class="optionItem-value purpose">
                  <div>
                    <span :class="{ hidden: hiddenTooLongText }" class="text">{{ selectedPurposesName }}</span>
                    <span class="subPurpose">{{ selectedSubPurposeName }}</span>
                  </div>
                </span>
                <span class="change"><img :src="smallPlusInfo" class="plus" alt="machicon" />変更</span>
              </div>
              <div class="closeLink alignCenter textSmall">
                <span @click="isDropped = !isDropped">{{ isDropped ? '閉じる' : '絞り込む' }}</span>
              </div>
            </div>
            <transition name="slideDown">
              <div v-show="isDropped" class="optionItemGroup list">
                <div :class="{ active: isSelectedPurpose }" class="optionItem condititon btn-select-tag" @click="openPurposeModal">
                  <span class="optionItem-value">
                    {{ isSelectedPurpose ? '目的選択済' : '目的から選ぶ' }}
                  </span>
                </div>
                <div :class="{ active: model.prefecture_slug }" class="optionItem condititon btn-select-tag" @click="modal = 'location'">
                  <span class="optionItem-value">
                    {{ selectedPrefectureName }}
                  </span>
                </div>
                <div :class="dateClasses" class="optionItem condititon btn-select-tag" @click="modal = 'calendar'">
                  <span class="optionItem-value">
                    {{ selectedDateFormatHoliday || '開催日を選ぶ' }}
                  </span>
                </div>
                <div :class="{ active: isCheckedConditions }" class="optionItem condititon btn-select-tag" @click="openModalSearch">
                  <span class="optionItem-value">
                    <span class="filter"> 絞り込む </span>
                  </span>
                </div>
              </div>
            </transition>
          </div>
        </transition>
        <div v-show="side === 'form'" class="searchConditions-content selectForm">
          <div class="optionItemGroup list">
            <div :class="{ active: isSelectedPurpose }" class="optionItem condititon btn-select-tag" @click="openPurposeModal">
              <span class="optionItem-value">
                {{ isSelectedPurpose ? '目的選択済' : '目的から選ぶ' }}
              </span>
            </div>
            <div :class="{ active: model.prefecture_slug }" class="optionItem condititon btn-select-tag" @click="modal = 'location'">
              <span class="optionItem-value">
                {{ selectedPrefectureName }}
              </span>
            </div>
            <div :class="dateClasses" class="optionItem condititon btn-select-tag" @click="modal = 'calendar'">
              <span class="optionItem-value">
                {{ selectedDateFormatHoliday || '開催日を選ぶ' }}
              </span>
            </div>
            <div :class="{ active: isCheckedConditions }" class="optionItem condititon btn-select-tag" @click="openModalSearch">
              <span class="optionItem-value">
                <span class="filter"> 絞り込む </span>
              </span>
            </div>
          </div>
        </div>
      </template>
    </div>
    <simple-search-modal
      :modal="modal"
      :modal-data="modalData"
      :position="currentYPosition"
      :parent-model="model"
      @close-modal="modal = ''"
      @apply-purposes="applyPurposes"
      @apply-location="applyLocation"
      @apply-feature="applyFeature"
      @apply-date="applyDate"
    />
    <sp-search v-if="modalSearch" :position="currentYPosition" @close-modal="closeModalSearch" />
  </div>
</template>

<script>
import { flattenArray, uniqArray } from '@/utils/array'
import { findPrefectureBySlug } from '@/models/prefectures'
import EventModel from '@/models/events'
import SearchParams from '@/utils/search/SearchParams'
import SearchLink from '@/utils/search/SearchLink'
import PageDetector from '@/utils/PageDetector'
import GlobalCookie from '@/utils/GlobalCookie'
import { defaultTimeSearchCondition as defaultTime } from '@/utils/Time'
import SimpleSearchMixin from '@/utils/mixins/SimpleSearch'
const SearchForm = defineAsyncComponent(() => import('@/components/search/SearchForm.vue'))
import SearchFormMixin from '@/utils/mixins/SearchDetailForm'
const NumberCounter = defineAsyncComponent(() => import('@/components/NumberCounter.vue'))
import smallPlusInfo from '/assets/images/svg/small_plus_info.svg'

const SpSearch = defineAsyncComponent(() => import('@/components/search/SpSearch.vue'))
const SimpleSearchModal = defineAsyncComponent(() => import('@/components/modals/SimpleSearchModal.vue'))
const ModalCalendar = defineAsyncComponent(() => import('@/components/calendar/ModalCalendar.vue'))
const SHOW_SEARCH_HEADER_DISTANCE = 800
const SHOW_SEARCH_HEADER_DISTANCE_ON_KONKATSU_PAGE = 2000
const SHOW_SEARCH_HEADER_DISTANCE_ON_INTEREST_PAGE = 1200
const CONTENT_EVENT_TYPES = {
  thoroughly: ['machicon', 'petit-con'],
  many_people: ['machicon', 'party'],
  konkatsu: 'konkatsu'
}

export default {
  components: { ModalCalendar, SimpleSearchModal, SpSearch, SearchForm, NumberCounter },
  mixins: [SimpleSearchMixin, SearchFormMixin],
  props: {
    mode: { type: String, default: 'normal', required: false },
    showSearchForm: { type: Boolean, default: false, required: false }
  },
  data() {
    return {
      prefectures: [],
      side: 'form',
      autoSubmitSearch: true,
      needRemoveQueries: {
        prefecture_slug: ['prefecture_slug', 'prefecture'],
        region: ['prefecture[]', 'region'],
        purpose: ['cat_in[]'],
        syumiplusPurposes: ['feature[]'],
        interestPurposes: ['interest[]'],
        areas: ['area', 'area[]'],
        onlineEventTypes: ['online_event_type[]'],
        start_date: ['from-day', 'from-month', 'from-year'],
        end_date: ['to-day', 'to-month', 'to-year'],
        start_dates: ['start_dates[]'],
        from_time: 'from-time',
        features: ['feature'],
        have_interest: 'have-interest'
      },
      modalSearch: false,
      isDropped: false,
      currentYPosition: 0,
      smallPlusInfo
    }
  },
  computed: {
    defaultTime,
    pageDetector() {
      return new PageDetector(this.$route)
    },
    searchParams() {
      return new SearchParams(this.$store, this.$route).apiSearchParams
    },
    searchConditions() {
      // search conditions that returned from api
      const conditions = this.$store.$state.search_page.search_conditions || {}
      // merge params with search conditions (values)
      return { ...this.searchParams, ...conditions }
    },
    prefecturesFilter() {
      if (!this.searchConditions['filter[prefecture]']) return []
      const prefectures = []
      const slugs = this.searchConditions['filter[prefecture]'].split(',')
      slugs.forEach(slug => {
        const pref = findPrefectureBySlug(slug)
        if (pref) prefectures.push(pref.name)
      })
      return prefectures
    },
    locationCondition() {
      if (this.prefecturesFilter && this.prefecturesFilter.length === 1) {
        return this.prefecturesFilter[0].replace(/(県|都|府)$/, '')
      } else {
        return '全国'
      }
    },
    contentTypes() {
      return this.searchConditions['filter[content_type]'] ? this.searchConditions['filter[content_type]'].split(',') : []
    },
    eventTypeNames() {
      if (this.pageDetector.isActivityDetailPage || this.pageDetector.isActivityTopPage) return [this.getEventTypeName('activity')]

      const eventTypes = this.searchConditions['filter[event_type]'] ? this.searchConditions['filter[event_type]'].split(',') : []
      if (eventTypes.length === 1 && !this.pageDetector.isSearchEventsPage) return [this.getEventTypeName(eventTypes)]

      const eventTypesByContent = uniqArray(flattenArray(this.contentTypes.map(v => CONTENT_EVENT_TYPES[v]).filter(v => v)))
      if (eventTypes.length === 0) return eventTypesByContent.map(v => this.getEventTypeName(v))
      if (eventTypesByContent.length === 0) return eventTypes.map(v => this.getEventTypeName(v))

      return eventTypes.filter(v => eventTypesByContent.includes(v)).map(v => this.getEventTypeName(v))
    },
    interestNames() {
      if (this.pageDetector.isActivityDetailPage || this.pageDetector.isActivityTopPage) return []

      const interestNames = []
      const interests = this.searchConditions.interests
      const haveInterest = Number(this.searchConditions['filter[have_interest]']) === 1 || this.contentTypes.includes('have_interest')

      if (interests) interestNames.push(interests.map(v => v.name))
      if (haveInterest && interestNames.length === 0) interestNames.push('趣味コン')

      return uniqArray(flattenArray(interestNames))
    },
    genreConditions() {
      if (this.$route.query['cat_in[]']) {
        const purposes = flattenArray([this.$route.query['cat_in[]']])
        const eventTypes = EventModel.getEventTypesByPurposes(purposes)
        const eventTypeNames = eventTypes.map(eventType => this.getEventTypeName(eventType)).filter(v => v)
        if (this.searchConditions.feature_tag) eventTypeNames.push(this.searchConditions.feature_tag.name)
        return uniqArray(eventTypeNames).join(', ')
      }
      if (this.eventTypeNames.length > 0 || this.interestNames.length > 0) {
        return uniqArray([...this.eventTypeNames, ...this.interestNames]).join(', ')
      }
      if (this.searchConditions.feature_tag) {
        return this.searchConditions.feature_tag.name
      }
      return '街コン（全イベント）'
    },
    searchDetailsPagePath() {
      const searchLink = new SearchLink(this.$store, this.$route, { name: 'search', query: { search_from: 'events' } })
      return searchLink.queryParamsForSearchDetails().toRoute
    },
    scrollDiff() {
      return this.$store.$state.window.scroll_top - this.$store.$state.window.old_scroll_top
    },
    isFirstTimeAccessKonkatsuPage() {
      const globalCookie = new GlobalCookie(this.$store)
      return globalCookie.firstTimeAccessPage && !this.$store.isLoggedIn && this.$store.isSP && this.pageDetector.isKonkatsuEventsPages
    },
    canShowHeader() {
      if (this.isFirstTimeAccessKonkatsuPage)
        return this.$store.$state.window.scroll_top > SHOW_SEARCH_HEADER_DISTANCE_ON_KONKATSU_PAGE && this.scrollDiff > 0
      if (this.pageDetector.isInterestsPage)
        return this.$store.$state.window.scroll_top > SHOW_SEARCH_HEADER_DISTANCE_ON_INTEREST_PAGE && this.scrollDiff > 0
      return this.$store.$state.window.scroll_top > SHOW_SEARCH_HEADER_DISTANCE && this.scrollDiff > 0
    },
    modeNormal() {
      return this.mode === 'normal'
    },
    modeHeader() {
      return this.mode === 'header'
    },
    searchQueryParams() {
      this.removeFeatureIfPurposeExisted()
      const formData = { ...this.model, prefectures: this.model.prefecture_slug ? [this.model.prefecture_slug] : [], features: this.model.features }
      return this.searchForm.convertFormDataToQueryParams(formData)
    },
    hiddenTooLongText() {
      return this.model.purposes[0] === 'tomokatsu' || this.model.purposes[0] === 'same_hobbies' || this.model.purposes[0] === 'jibunmigaki'
    },
    isSelectedPurpose() {
      return this.model.purposes.length > 0 || this.model.interestPurposes.length > 0 || this.model.features.includes('counseling')
    },
    isFeatureTagSelected() {
      return this.model.features && this.model.features.length > 0 && this.model.syumiplusPurposes.length === 0
    },
    dateClasses() {
      const classes = []
      if (this.model.start_date || this.model.end_date || this.model.start_dates.length > 0) classes.push('active')
      if (this.selectedPrefectureName === 'エリアを選ぶ') classes.push('maxWidth6Chars')
      if (this.selectedPrefectureName.length === 3) classes.push('maxWidth3Chars')
      if (this.selectedPrefectureName.length === 4) classes.push('maxWidth4Chars')
      return classes
    }
  },
  watch: {
    canShowHeader() {
      if (this.modeHeader) {
        this.changeSearchToForm()
      } else {
        this.changeSearchToShow()
      }
    }
  },
  mounted() {
    this.side = this.canShowHeader ? 'show' : 'form'
  },
  methods: {
    removeFeatureIfPurposeExisted() {
      if (
        !this.$store.isSP &&
        ((this.model.syumiplusPurposes && this.model.syumiplusPurposes.length > 0) ||
          (this.model.interestPurposes && this.model.interestPurposes.length > 0))
      )
        this.model.features = []
    },
    openPurposeModal() {
      this.modal = 'purpose'
      if (this.$store.isSP) {
        this.currentYPosition = window.pageYOffset
        document.body.classList.add('openModal')
      }
      if (this.pageDetector.isFeaturesEventsPage) {
        this.model.features = []
      }
    },
    openModalSearch() {
      this.modalSearch = true
      this.currentYPosition = window.pageYOffset
      document.body.classList.add('openModal')
    },
    getEventTypeName(eventType) {
      if (eventType === 'other') return '友達作り'
      return this.$useI18(`event.attributes.event_type.${eventType}`)
    },
    closeModalSearch() {
      this.modalSearch = false
    },
    changeSearchToForm() {
      this.side = 'show'
    },
    changeSearchToShow() {
      this.side = 'form'
    }
  }
}
</script>

<style lang="scss" scoped>
.slideDown {
  &-enter-active {
    transition: height 0.3s ease-in;
  }
  &-leave-active {
    transition: 0s ease-out;
  }
  &-enter-from,
  &-leave-to {
    height: 0;
    opacity: 0;
    overflow: hidden;
  }
  &-enter-to,
  &-leave-from {
    opacity: 1;
    overflow: auto;
  }
}

.headerSlideDown {
  &-enter {
    &-from {
      transform: translateY(-100%);
    }
    &-active {
      transition: transform 0.3s;
    }
  }
  &-leave-active {
    transition: 0s linear;
  }
  &-enter-from,
  &-leave-to {
    height: 0;
    opacity: 0;
    overflow: hidden;
  }
  &-enter-to,
  &-leave-from {
    opacity: 1;
    overflow: auto;
  }
}

.searchConditions {
  position: relative;
  &.headerMode {
    position: fixed;
    top: 0;
    width: 100%;
    display: flex;
    transition: 0.2s;
    transform: translateY(-100%);
    z-index: 1100;
    &.active {
      transform: translateY(0);
      .selectForm {
        box-shadow: 0 3px 6px $black-016;
      }
      .listConditions {
        background: $white;
        box-shadow: 0 3px 6px $black-016;
      }
    }
    .selectForm {
      padding: 1rem 1.6rem;
    }
  }
  &.normalMode {
    @include pc {
      background: $gray-f1;
      padding: 2rem 1rem 0.8rem;
    }
  }
  &-content {
    display: flex;
    color: $blue-pale;
    font-weight: 700;
    @include pc {
      padding: 1rem 1rem;
    }
    @include sp {
      padding: 0 1.6rem;
      width: 100%;
    }
    &.selectForm {
      display: block;
    }
  }
}

.listConditions {
  font-size: 1.6rem;
  padding: 1rem 1.6rem;
  justify-content: space-between;
  &-searchIcon {
    padding: 1rem 0;
    float: left;
    @include sp {
      padding: 1.5rem 0;
    }
  }
  &-content {
    padding-left: 9px;
    white-space: nowrap;
    overflow: hidden;
    @include sp {
      padding: 0.6rem 0 0.6rem 9px;
    }
    &-item {
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }
}

.optionItemGroup {
  display: flex;
  &.list {
    flex-wrap: wrap;
  }
}

.optionItem {
  display: flex;
  padding: 0.8rem;
  width: 100%;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  align-items: center;
  &:last-child {
    margin-bottom: 0;
  }
  &.condititon {
    width: auto;
    margin-top: 1.2rem;
    @include pc {
      cursor: pointer;
    }
    @include sp {
      &:nth-child(1),
      &:nth-child(2) {
        padding-top: 0.9rem;
      }
    }
    &.maxWidth6Chars {
      max-width: calc(100% - 11.2rem);
    }
    &.maxWidth3Chars {
      max-width: calc(100% - 7rem);
    }
    &.maxWidth4Chars {
      max-width: calc(100% - 8.4rem);
    }
    &:not(:last-child) {
      margin-right: 0.6rem;
    }
  }
  &.active {
    .optionItem-value {
      @include textEllipsis;
      color: $white;
      font-weight: 700;
    }
    .time {
      background: $cerulean-blue;
      width: 70px;
    }
    .filter {
      color: $white;
      g {
        stroke: $white;
      }
    }
  }
  &-value {
    font-size: 1.4rem;
    font-weight: 500;
    color: $black-1a;
    &.activePurpose {
      color: $cerulean-blue;
      font-weight: bold;
      width: 85%;
    }
    &.disable {
      color: $gray-c6;
    }
    &.time {
      @include pc {
        cursor: pointer;
      }
      height: 30px;
      background: $white;
      border: none;
      outline: none;
      option {
        color: $black-1a;
      }
    }
    .filter {
      cursor: pointer;
      @include pc {
        color: $black-1a;
      }
      .icon {
        position: relative;
        top: 1px;
      }
    }
    &.purpose {
      width: 90%;
      overflow: hidden;
      text-overflow: ellipsis;
      .subPurpose {
        margin: auto 0.5rem;
        padding-right: 0.3rem;
        font-weight: normal;
        font-size: 1.2rem;
        @include textEllipsis;
      }
    }
  }
  &.inputSearch {
    background: $white;
    justify-content: space-between;
    position: relative;
    box-shadow: 0 1px 1px $black-016;
    border-radius: 21px;
    @include pc {
      cursor: pointer;
    }
    .icon {
      display: block;
      position: absolute;
      left: 0;
      top: 0;
      width: 4rem;
      height: 4rem;
      background: $gray-bb;
      border-radius: 50%;
      border: 2px solid $white;
      font-size: 1.4rem;
      line-height: 4rem;
      text-align: center;
      pointer-events: none;
      color: $white;
      &.active {
        background: $cerulean-blue;
      }
    }
    .text {
      font-size: 1.6rem;
      padding-left: 3.5rem;
      &.feature {
        @include textEllipsis;
        width: 90%;
      }
      &.hidden {
        @include pc {
          overflow: hidden;
          text-overflow: ellipsis;
        }
      }
    }
    .change {
      position: absolute;
      right: 15px;
      top: 12px;
      font-size: 1.2rem;
      color: $cerulean-blue;
      .plus {
        margin-right: 0.4rem;
      }
      @media screen and (max-width: 320px) {
        right: 12px;
      }
    }
  }
}

.closeLink {
  height: 40px;
  background: $green-dark;
  padding: 1.3rem 0.8rem;
  margin-left: 0.6rem;
  border-radius: 21px;
  font-size: 1.4rem;
  line-height: 14px;
  color: $white;
  text-align: center;
  white-space: nowrap;
}
</style>
