import { useMainStore } from '@/store'
import { addTrailingSlash } from '@/utils/url-action'
import { useNuxtApp } from 'nuxt/app'

const useInternalFetch = async function () {
  let url, method, params, options
  if (arguments.length === 4) {
    url = arguments[0]
    method = arguments[1]
    params = arguments[2]
    options = arguments[3]
  } else if (arguments.length === 3) {
    url = arguments[0]
    method = arguments[1]
    options = arguments[2]
  } else {
    throw new Error('Invalid arguments')
  }
  const app = useNuxtApp()
  const store = useMainStore()
  const baseURL = import.meta.client ? import.meta.env.BASE_API_URL : import.meta.env.BASE_SSR_API_URL

  const defaults =
    method === 'get'
      ? {
          baseURL,
          method,
          credentials: 'include',
          onRequest({ request, options }) {
            // FIXME: useFetchのバグかも知れないので、修正されたらこの処理も削除する
            // Nuxtへのリクエストヘッダのcontent-lengthがバイパスされる。
            // GETメソッドの場合、bodyはないのでcontent-lengthヘッダを削除する
            if (options.headers && options.headers['content-length']) delete options.headers['content-length']
          }
        }
      : {
          baseURL,
          method,
          credentials: 'include'
        }

  if (params) {
    defaults.body = params
  }

  if (options.headers && Object.keys(options.headers).length > 0) {
    defaults.headers = options.headers
  } else if (import.meta.server) {
    let cookieString = ''
    const cookieObject = store.ssrCookie
    const cookieStringArr = []
    for (const [key, value] of Object.entries(cookieObject)) {
      cookieStringArr.push(`${key}=${encodeURIComponent(value)}`)
    }
    cookieString = cookieStringArr.join('; ')
    defaults.headers = {
      cookie: cookieString
    }
  }

  const { data: res, error, refresh } = await useFetch(addTrailingSlash(url), defaults)
  if (error.value) {
    if (error.value.statusCode === 401) store.SET_STATE({ key: 'auth_info', value: null })
    error.value.status = error.value.statusCode
    throw { response: error.value }
  }
  if (res.value == null) await refresh()
  const resFetch = { data: res.value }
  return resFetch
}

const useAsyncFetch = async function () {
  let url, method, params, options
  if (arguments.length === 4) {
    url = arguments[0]
    method = arguments[1]
    params = arguments[2]
    options = arguments[3]
  } else if (arguments.length === 3) {
    url = arguments[0]
    method = arguments[1]
    options = arguments[2]
  } else {
    throw new Error('Invalid arguments')
  }
  const app = useNuxtApp()
  const store = useMainStore()
  const baseURL = import.meta.client ? import.meta.env.BASE_API_URL : import.meta.env.BASE_SSR_API_URL

  const defaults = {
    baseURL,
    method,
    credentials: 'include'
  }

  if (params) {
    defaults.body = params
  }

  if (options.headers && Object.keys(options.headers).length > 0) {
    defaults.headers = options.headers
  } else if (import.meta.server) {
    let cookieString = ''
    const cookieObject = store.ssrCookie
    const cookieStringArr = []
    for (const [key, value] of Object.entries(cookieObject)) {
      cookieStringArr.push(`${key}=${encodeURIComponent(value)}`)
    }
    cookieString = cookieStringArr.join('; ')
    defaults.headers = {
      cookie: cookieString
    }
  }

  const { data: res, error, refresh } = await useAsyncData(() => $fetch(addTrailingSlash(url), defaults))
  if (error.value) {
    if (error.value.statusCode === 401) store.SET_STATE({ key: 'auth_info', value: null })
    error.value.status = error.value.statusCode
    throw { response: error.value }
  }
  if (res.value == null) await refresh()
  const resFetch = { data: res.value }
  return resFetch
}

const useAsyncFetchV2 = async function () {
  let url, method, params, options
  if (arguments.length === 4) {
    url = arguments[0]
    method = arguments[1]
    params = arguments[2]
    options = arguments[3]
  } else if (arguments.length === 3) {
    url = arguments[0]
    method = arguments[1]
    options = arguments[2]
  } else {
    throw new Error('Invalid arguments')
  }
  const app = useNuxtApp()
  const store = useMainStore()
  const baseURL = import.meta.client ? import.meta.env.BASE_API_URL : import.meta.env.BASE_SSR_API_URL

  const defaults = {
    baseURL,
    method,
    credentials: 'include'
  }

  if (params) {
    defaults.body = params
  }

  if (options.headers && Object.keys(options.headers).length > 0) {
    defaults.headers = options.headers
  } else if (import.meta.server) {
    let cookieString = ''
    const cookieObject = store.ssrCookie
    const cookieStringArr = []
    for (const [key, value] of Object.entries(cookieObject)) {
      cookieStringArr.push(`${key}=${encodeURIComponent(value)}`)
    }
    cookieString = cookieStringArr.join('; ')
    defaults.headers = {
      cookie: cookieString
    }
  }

  const addedTrailingSlashUrl = addTrailingSlash(url)
  const key = addedTrailingSlashUrl.replace(baseURL, '')
  const { data: res, error, refresh } = await useAsyncData(key, () => $fetch(addedTrailingSlashUrl, defaults))
  if (error.value) {
    if (error.value.statusCode === 401) store.SET_STATE({ key: 'auth_info', value: null })
    error.value.status = error.value.statusCode
    throw { response: error.value }
  }
  if (res.value == null) await refresh()
  const resFetch = { data: res.value }
  return resFetch
}

export { useInternalFetch, useAsyncFetch, useAsyncFetchV2 }
