import validate from "/var/www/mj_client/releases/20241114051157/node_modules/nuxt/dist/pages/runtime/validate.js";
import before_45enter_45global from "/var/www/mj_client/releases/20241114051157/middleware/beforeEnter.global.js";
import check_45private_45pages_45global from "/var/www/mj_client/releases/20241114051157/middleware/checkPrivatePages.global.js";
import redirect_45date_45global from "/var/www/mj_client/releases/20241114051157/middleware/redirectDate.global.js";
import set_45header_45response_45global from "/var/www/mj_client/releases/20241114051157/middleware/setHeaderResponse.global.js";
import manifest_45route_45rule from "/var/www/mj_client/releases/20241114051157/node_modules/nuxt/dist/app/middleware/manifest-route-rule.js";
export const globalMiddleware = [
  validate,
  before_45enter_45global,
  check_45private_45pages_45global,
  redirect_45date_45global,
  set_45header_45response_45global,
  manifest_45route_45rule
]
export const namedMiddleware = {
  authenticated: () => import("/var/www/mj_client/releases/20241114051157/middleware/authenticated.js"),
  "authenticated-with-redirect": () => import("/var/www/mj_client/releases/20241114051157/middleware/authenticatedWithRedirect.js"),
  "redirect-area": () => import("/var/www/mj_client/releases/20241114051157/middleware/redirectArea.js"),
  "redirect-categories": () => import("/var/www/mj_client/releases/20241114051157/middleware/redirectCategories.js"),
  "redirect-child-interests": () => import("/var/www/mj_client/releases/20241114051157/middleware/redirectChildInterests.js"),
  "redirect-event": () => import("/var/www/mj_client/releases/20241114051157/middleware/redirectEvent.js"),
  "redirect-interests": () => import("/var/www/mj_client/releases/20241114051157/middleware/redirectInterests.js"),
  "redirect-online-event": () => import("/var/www/mj_client/releases/20241114051157/middleware/redirectOnlineEvent.js"),
  "redirect-search-by-date-path": () => import("/var/www/mj_client/releases/20241114051157/middleware/redirectSearchByDatePath.js"),
  "redirect-user-name-register-before-purchase": () => import("/var/www/mj_client/releases/20241114051157/middleware/redirectUserNameRegisterBeforePurchase.js"),
  "validate-area-page": () => import("/var/www/mj_client/releases/20241114051157/middleware/validateAreaPage.js")
}